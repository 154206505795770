.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color:#508fb9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: blanchedalmond;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  opacity: 98%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.bm-item:hover {
  color:#fff
}

.menu-logo {
  position: fixed;
  left: 36px;
  top: 28px;
  background-color:#3B2F2F;
  opacity: 90%;
  padding: 0.8em 1em 1.2em 1em;
}

.menu-logo a:visited {
  color:#fff
}

.menu-logo a:hover {
  color:blanchedalmond
}

.logo-top {
  font-size: 2em;
  text-transform: uppercase;
  font-family: "Neord";
}

.logo-bottom {
  letter-spacing: 0.3em;
  line-height: 0.5em;
  padding-left: 0.3em;
}

@media (min-width: 1300px) {
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .text {
    font-size: 2em;
    margin-bottom: 6em;
    width: 50vw;
  }
}

@media (max-width: 1299px) 
and (min-width: 481px) {
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: 
    linear-gradient(
      rgba(59, 47, 47, 0.8), 
      rgba(59, 47, 47, 0.8)
    ),
    url(/public/bkg.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
  }
  
  .text {
    font-size: 2em;
    padding: 8em 2em;
  }
}

@media (max-width: 481px) {
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: 
      linear-gradient(
        rgba(59, 47, 47, 0.8), 
        rgba(59, 47, 47, 0.8)
      ),
      url(/public/bkg.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
  }
  
  .text {
    font-size: 1.5em;
    padding: 8em 0.5em;
  }
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: blanchedalmond;
}

a:hover {
  color: white;
}

a:visited {
  color: white;
}

body{
  background: #3399ff;
}


.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}
