// @import '../../scss/variables.scss';

// .contact-page-component {
//   font-family: $font-sans-serif;
//   font-size: $font-size;

//   display: flex;
//   justify-content: center;
// }

@media (min-width: 1300px) {
  .contact-text {
    position: fixed;
    text-align: right;
    font-size: 2em;
    top: 16em;
    left: 29em;
  }
}

@media (max-width: 1299px) 
and (min-width: 481px) {
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-text {
    font-size: 2em;
    padding: 8em 2em;
  }
}

@media (max-width: 481px) {
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact-text {
    font-size: 1.5em;
    padding: 4em 0.5em;
  }
}

.contact-header {
  text-transform: uppercase;
}